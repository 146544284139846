import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { SectionFullscreenInfoProps } from '@liftfoils/models'
import { Link, PortableText, Media } from '@liftfoils/components'
import { validateLink } from '../Helpers/validateLink'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  $posterHeight: true,
})

const MediaWrap = styled('div', {
  gridRow: 1,
  gridColumn: 1,
})

const Overlay = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  gridTemplateRows: '1fr min-content 1fr',
  justifyItems: 'center',
  zIndex: 1,
  display: 'grid',
  gridGap: '$10',
  $py: 'L',
  px: '$containerMargin',
})

const CenterWrap = styled('div', {
  gridRow: 2,
  display: 'grid',
  justifyItems: 'center',
  gridGap: '$8',
})

const Title = styled('h2', {
  lift_font: 'caps02',
  color: 'white',
  textAlign: 'center',
  '& + p': {
    $mt: 'M',
  },
})
const Description = styled('div', {
  lift_font: 'heading01',
  textAlign: 'center',
  maxWidth: '650px',
  color: '$white_06',
  '.highlighted': {
    color: 'white',
  },
})

const BottomWrap = styled('div', {
  gridRow: 3,
  display: 'grid',
  alignSelf: 'end',
})

export const SectionFullscreenInfo: FC<SectionFullscreenInfoProps> = ({
  title,
  description,
  buttonLink,
  buttonLabel,
  background,
  priority,
}) => {
  return (
    <Wrap>
      {background?.mediaPayload && (
        <MediaWrap>
          <Media
            {...background}
            layout={'fill'}
            priority={priority}
            sizes="100vw"
            hardcropRatio={{
              '856': 'square',
              '1440': 'landscape2',
              '1920': 'landscape2',
              '2880': 'landscape1',
            }}
            controlsPosition={'bottom'}
            alt={background?.alt ?? ''}
          />
        </MediaWrap>
      )}

      <Overlay>
        <CenterWrap>
          {title && <Title>{title}</Title>}
          {description && (
            <Description>
              <PortableText value={description} />
            </Description>
          )}
        </CenterWrap>

        {buttonLabel && validateLink(buttonLink) && (
          <BottomWrap>
            <Link size={'large'} {...buttonLink} appearance={'solidWhite'}>
              {buttonLabel}
            </Link>
          </BottomWrap>
        )}
      </Overlay>
    </Wrap>
  )
}
